import { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import Spinner from 'react-bootstrap/Spinner';
import { useMediaQuery } from '@mui/material';

import { colors } from '../shared/constants';
import { GlobalContext } from '../App';
import { TOAST_TYPE } from '../hooks/use-toast';
import { API_URL, TIME_DELAY } from '../conf';

export const PhotoBox = ({
  id,
  placeholderImage,
  title,
  required,
  errorMessage,
  generated,
  pictureState,
  state,
  setState,
  setPictureState,
  onStatusChange = () => {},
}) => {
  const { toast, cameraOnly, setIsAnyPhotoBoxLoading } = useContext(GlobalContext);
  const { triggerToast } = toast;
  const [source, setSource] = useState('');
  const [base64, setBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const tablet = useMediaQuery('(max-width:768px)');

  const styles = {
    box: {
      backgroundColor: colors.white,
      color: colors.white,
      width: tablet ? '180px' : '16rem',
      height: tablet ? '180px' : '14rem',
      fontSize: tablet ? '1.5rem' : '1.8rem',
      border: `5px ${source ? 'solid' : 'dotted'} ${source ? colors.positive : colors.accent}`,
      borderRadius: '5px',
      position: 'relative'
    },
    boxFail: {
      backgroundColor: colors.white,
      color: colors.white,
      width: tablet ? '180px' : '16rem',
      height: tablet ? '180px' : '14rem',
      fontSize: tablet ? '1.5rem' : '1.8rem',
      border: `5px solid ${colors.negative}`,
      borderRadius: '5px',
      position: 'relative'
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
    placeholder: {
      opacity: 0.4
    },
    invisibleInput: {
      display: 'none'
    },
    photoLabel: {
      padding: `${source ? '0' : '1.2rem'}`,
      width: '100%',
      height: '100%'
    },
    boxContainer: {
      padding: '1rem'
    },
    titleError: {
      color: 'blue'
    },
    deleteContainer: {
      position: 'absolute',
      right: -30,
      top: -30,
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      direction: 'column',
      justifyContent: 'center',
      background: 'none',
      color: 'none',
      border: 'none'
    },
    spinnerContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center'
    },
    errorContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.negative
    }
  };

  const handleCapture = async (target) => {
    setIsLoading(true);
    setIsAnyPhotoBoxLoading(true);
    setUploadStatus(null);

    if (target.files && target.files.length !== 0) {
      const file = target.files[0];
      console.log(`File size: ${file.size} bytes`);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 3000,
        useWebWorker: true,
        maxIteration: 20,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        console.log(`Compressed file size: ${compressedFile.size} bytes`);
        const newUrl = URL.createObjectURL(compressedFile);
        setSource(newUrl);

        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          const base64Image = reader.result;
          setBase64(base64Image);
          uploadImage(base64Image);
        };
      } catch (error) {
        console.error('Compression error:', error);
        setIsLoading(false);
        setIsAnyPhotoBoxLoading(false);
        setUploadStatus('failed');
        onStatusChange(id, 'failed');
      }
    } else {
      setIsLoading(false);
      setIsAnyPhotoBoxLoading(false);
      setUploadStatus('failed');
      onStatusChange(id, 'failed')
    }
  };

  const getClientInfo = () => {
    let device = 'unknown';
    let browserBrand = 'unknown';
    let browserVersion = 'unknown';
  
    if (navigator.userAgentData) {
      const { platform, brands } = navigator.userAgentData;
      device = platform || 'unknown';
  
      if (brands && brands.length > 0) {
        browserBrand = brands[0].brand;
        browserVersion = brands[0].version || 'unknown';
      }
    } else {
      const userAgent = navigator.userAgent || 'unknown';
      if (/android/i.test(userAgent)) {
        device = 'Android';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        device = 'iOS';
      } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
        device = 'Mac';
      } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
        device = 'Windows';
      } else if (/Linux/.test(userAgent)) {
        device = 'Linux';
      }
      browserBrand = 'Legacy Browser';
      browserVersion = 'unknown';
    }
  
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
    return {
      browser: `${browserBrand} ${browserVersion}`,
      device,
      connectionType: connection.effectiveType || 'unknown',
      downlink: connection.downlink || 'unknown',
      rtt: connection.rtt || 'unknown',
    };
  };
  
  

  const uploadImage = async (base64Image) => {
    const clientInfo = getClientInfo();
    try {
      const response = await axios.post(API_URL + '/api/scu/upload-file', { file: base64Image, token: localStorage.getItem('token'), clientInfo });
      setPictureState((prevState) => ({ ...prevState, [id]: response.data.data }));
      setUploadStatus('success');
      onStatusChange(id, 'success')
    } catch (error) {
      if (error.response && error.response.status === 413) {
        triggerToast('Zdjęcie jest zbyt duże', TOAST_TYPE.DANGER, TIME_DELAY.LONG);
      } else {
        triggerToast('Nie udało się przesłać zdjęcia - błąd serwera', TOAST_TYPE.DANGER, TIME_DELAY.MEDIUM);
      }
      setUploadStatus('failed');
      onStatusChange(id, 'failed')
    } finally {
      setIsLoading(false);
      setIsAnyPhotoBoxLoading(false);
    }
  };

  const lastItemId = state?.[state?.length - 1]?.id === id;

  const handleDelete = (e) => {
    e.preventDefault();
    const filteredState = state?.filter((field) => field.id !== id);
    setState(filteredState);
    delete pictureState[id];
    setPictureState(pictureState);
  };

  const formValidationFailed = errorMessage?.length > 0;

  return (
    <div className="d-flex flex-column align-items-center" style={styles.boxContainer}>
      <h3 className={`photo-box-label ${!source && formValidationFailed && required ? 'text-danger' : ''}`}>{title}</h3>
      <div style={uploadStatus === 'failed' ? styles.boxFail : source ? styles.box : formValidationFailed && required ? styles.boxFail : styles.box}>
        {lastItemId && generated && (
          <button onClick={(e) => handleDelete(e)} style={styles.deleteContainer}>
            <XCircleFill className="photobox-delete-button" />
          </button>
        )}
        <label htmlFor={id} style={styles.photoLabel}>
          {isLoading ? (
            <div style={styles.spinnerContainer}>
              <Spinner animation="border" role="status" variant="primary" style={{ width: "80px", height: "80px" }} />
            </div>
          ) : uploadStatus === 'failed' ? (
            <div style={styles.errorContainer}>Nie zapisano</div>
          ) : (
            <img
              src={source ? source : placeholderImage}
              style={source ? styles.image : { ...styles.image, ...styles.placeholder }}
              alt="placeholder"
            />
          )}
        </label>
      </div>
      <Form.Control
        required={required}
        id={id}
        type="file"
        capture={cameraOnly ? "environment" : null}
        onChange={(e) => {
          handleCapture(e.target);
        }}
        style={styles.invisibleInput}
      />
    </div>
  );
};
